import _ from "lodash";
import Alpine from "alpinejs";
import { canvas, createCanvas, generate, initLogo, set } from "./logoza";
import { initStore, config } from "./config";

initStore();

function canvasResizeToParent() {
    const wrapper = canvas.lowerCanvasEl.parentNode;
    canvas.setDimensions({ width: wrapper.clientWidth, height: wrapper.clientHeight });
    generate();
}

function initListeners() {
    window.onload = function () {
        const canvasResizeToParentDebounced = _.debounce(canvasResizeToParent, 128);

        new ResizeObserver(() => {
            canvasResizeToParentDebounced();
        }).observe(document.getElementById("workspace"));

        canvasResizeToParent();
    }
}

createCanvas();
initListeners();
initLogo().then(() => {
    generate();
    canvas.lowerCanvasEl.classList.add("ready");
});

Alpine.data("preloader", () =>({
    
}))

Alpine.data("btnLayouts", () => ({
    open: false,
    currentLayout: {
        ["@click"]() {
            this.open = !this.open;
        }
    },
    currentLayoutImg: {
        [":src"]() {
            return `static/img/layout${config.layout}.svg`;
        }
    },
    layouts: {
        ["x-transition"]() { return true },
        ["x-show"]() {
            return this.open
        },
        ["@click.outside"]() {
            this.open = false;
        }
    },
    layout: {
        [":class"]() {
            if (this.$data.i == config.layout) return "on";
        },
        ["@click"]() {
            set("layout", this.$data.i);
            //this.open = false;
        }
    }
}))


Alpine.data("coordsControl", () => ({
    key: "",
    interval: null,
    timeout: null,
    ms: 128,
    init() {
        this.key = this.$root.dataset.key;
    },
    startSetter(path, val, opts) {
        const _path = this.key ? `objects.${this.key}.${path}` : path;

        set(_path, val, opts);
        this.stopSetter();

        this.timeout = setTimeout(() => {
            this.interval = setInterval(() => {
                set(_path, val, opts)
            }, this.ms);
        }, this.ms * 2);
    },
    stopSetter() {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    },
    touched(el) {
        const opts = el.dataset.opts ? JSON.parse(el.dataset.opts) : undefined;
        this.startSetter(el.dataset.key, el.dataset.val, opts)
    },
    control: {
        ["@mousedown.prevent.stop"]() { this.touched(this.$el) },
        ["@touchstart.prevent.stop"]() { this.touched(this.$el) },
        ["@mouseup.prevent.stop"]() { this.stopSetter() },
        ["@touchend.prevent.stop"]() { this.stopSetter() }
    }
}))



Alpine.start();